import React, { useEffect, useState } from "react";
import "./Home.css";
import { ListGroup } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { getCustomers } from "../api/RestAPI";
import { onError } from "../libs/errorLib";
import { LinkContainer } from "react-router-bootstrap";
import { BsPencilSquare } from "react-icons/all";
import { MDBDataTable } from 'mdbreact';
import { useHistory } from "react-router-dom";
import {ICustomer} from "dinenation-types";

export interface column {
  label: string;
  field: string;
  width?: number;
  attributes?: {
    'aria-controls': string;
    'aria-label': string;
  },
}

const columns = [
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'First Last name',
    field: 'name',
  },
  {
    label: 'Username',
    field: 'username',
  },
  {
    label: 'Shipping Address',
    field: 'shippingAddress',
  },
  {
    label: 'Phone',
    field: 'phoneNumber',
  },
]

const Home: React.FC = () => {
  const [customersData, setCustomersData] = useState<{ columns: column[], rows: ICustomer[] }>();
  const {isAuthenticated} = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const {customers, setCustomers} = useAppContext();

  useEffect(() => {
    const onLoad = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        if (!customers || customers.length === 0) {
          const customersResponse = await getCustomers();
          setCustomers(customersResponse);
        }
        setCustomersData({columns: columns, rows: customers});
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, customers, setCustomers]);

  const renderCustomersList = () => {
    if (customersData) {
      const data = {
        columns: customersData.columns,
        rows: [
          ...customersData.rows.map((row: ICustomer) => ({
            ...row,
            name: (
              <p className="text-left">{row.firstName + ' ' + row.lastName}</p>
            ),
            clickEvent: () => history.push('/customers/' + row.id)
          })),
        ],
      };
      return (
        <>
          <LinkContainer to="/customers/new">
            <ListGroup.Item action className="py-3 text-nowrap text-truncate">
              <BsPencilSquare size={17}/>
              <span className="ml-2 font-weight-bold">Create a new customer</span>
            </ListGroup.Item>
          </LinkContainer>
          <MDBDataTable
            striped
            bordered
            hover
            data={data}
          />
        </>
      );
    }
  }

  const renderLander = () => {
    return (
      <div className="lander">
        <h1>Dinenation</h1>
        <p className="text-muted">Dinenation admin panel</p>
      </div>
    );
  }

  const renderCustomers = () => {
    return (
      <div className="Customers">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">All customers</h2>
        <ListGroup>{!isLoading && renderCustomersList()}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderCustomers() : renderLander()}
    </div>
  );
}

export default Home;
