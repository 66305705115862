import {API} from 'aws-amplify';
import {IAddress, IBox, ICustomer, IKitchenOrder, IOrder, IUser} from "dinenation-types";

const get = async (path: string) => {
  return API.get('api', path, {});
};

const sync = async (path: string) => {
  return API.get('sync', path, {});
};

export const syncOrders = async () => {
  return await sync('/processingOrders');
}

const remove = async (path: string) => {
  return API.del('api', path, {});
};

const post = async (path: string, body: {}) => {
  return API.post('api', path, {
    body: body,
  });
};

const update = async (path: string, body: {}) => {
  return API.put('api', path, {
    body: body,
  });
};

export const getCustomers = async (): Promise<ICustomer[]> => {
  return get('/customers')
};

export const getAddresses = async (): Promise<IAddress[]> => {
  return get('/addresses')
};

export const getUsers = async (): Promise<IUser[]> => {
  return get('/users')
};

export const getUser = async (userId: string): Promise<IUser> => {
  return get('/users/' + userId)
};

export const getOrders = async (params?: {date?: string, orderStatus?: string}): Promise<IOrder[]> => {
  if (params && params.date && params.orderStatus) {
    return sync('/orders?date=' + params.date + '&orderStatus=' + params.orderStatus);
  } else {
    if (params?.date) {
      return sync('/orders?date=' + params.date);
    }
    if (params?.orderStatus) {
      return sync('/orders?orderStatus=' + params.orderStatus);
    }
  }
  return sync('/orders');
};

export const getBoxes = async (boxStatus?: string, weekday?: string): Promise<IBox[]> => {
  if (boxStatus && weekday) {
    return sync('/boxes?boxStatus=' + boxStatus + '&weekday=' + weekday);
  }
  if (boxStatus) {
    return sync('/boxes?boxStatus=' + boxStatus);
  }
  if (weekday) {
    return sync('/boxes?weekday=' + weekday);
  }
  return sync('/boxes');
};

export const closeOldOrders = async () => {
  return sync('/closeLastWeek');
};

export const getOrder = async (orderId: string): Promise<IOrder> => {
  return get('/orders/' + orderId);
};

export const getKitchenOrders = async (): Promise<IKitchenOrder> => {
  return get('/kitchenOrders?orderStatus=processing');
};

export const getCustomer = async (id: string): Promise<ICustomer> => {
  return get('/customers/' + id)
};

export const getAddress = async (id: string): Promise<IAddress> => {
  return get('/addresses/' + id)
};

export const createCustomer = async (customer: ICustomer) => {
  return post('/customers', customer);
}

export const removeCustomer = async (id: string) => {
  return remove('/customers/' + id)
};

export const updateCustomer = async (id: string, customer: any) => {
  return update('/customers/' + id, customer)
};

export const updateOrder = async (id: string, order: any) => {
  return update('/orders/' + id, order)
};

// export const updateBox = async (id: string, data: any) => {
//   return update('/boxes/' + id, data)
// };

export const getCoordinates = async (address: string) => {
  const response = await fetch('http://159.69.194.198/?address=' + encodeURI(address))
  if (response.status === 200) {
    return await response.json();
  }
};
