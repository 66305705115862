import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {onError} from "../libs/errorLib";
import {getCustomer, updateCustomer} from "../api/RestAPI";
import {Form} from "react-bootstrap";
import {MDBBtn, MDBInputGroup} from "mdbreact";
import {MDBSpinner} from "mdb-react-ui-kit";
import MyMapComponent from "../components/SingleMapComponent";
import {ICustomer} from "dinenation-types";

const regex1 = new RegExp("http[s]?:\\/\\/m.2gis.com.cy\\/cyprus\\/geo\\/\\d+\\/(\\d+.\\d+),(\\d+.\\d+).*");
const regex2 = new RegExp("http[s]?:\\/\\/2gis.com.cy\\/cyprus\\/geo\\/\\d+[?]m=(\\d+.\\d+),(\\d+.\\d+).*");
const regex3 = new RegExp("http[s]?:\\/\\/m.2gis.com.cy\\/cyprus\\/geo\\/\\d+[?]m=(\\d+.\\d+),(\\d+.\\d+).*");

interface ParamTypes {
  id: string
}

const Customers: React.FC = () => {
  const {id} = useParams<ParamTypes>();
  const [customer, setCustomer] = useState<ICustomer>();
  const [isLoading, setLoading] = useState(false);
  const [place, setPlace] = useState('');


  useEffect(() => {
    const onLoad = async () => {
      try {
        const customer: ICustomer = await getCustomer(id);

        setCustomer(customer);
      } catch (e) {
        console.log('error')
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  const updateCoordinates = async () => {
    const targetString = decodeURIComponent(place);
    let regex = regex1;
    if (regex2.test(targetString)) {
      regex = regex2;
    }
    if (regex3.test(targetString)) {
      regex = regex3;
    }
    const lat = targetString.replace(regex, "$2")
    const lng = targetString.replace(regex, "$1")
    console.log(lat + ', ' + lng)
    if (customer) {
      setLoading(true)
      await updateCustomer(id, {
        shippingLat: parseFloat(lat),
        shippingLng: parseFloat(lng),
      });
      const updatedCustomer: ICustomer = await getCustomer(id);
      setCustomer(updatedCustomer);
      setLoading(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);
    try {
      setLoading(false);
    } catch (e) {
      onError(e);
      setLoading(false);
    }
  }

  const renderMap = () => {
    if (isLoading) {
      return <MDBSpinner/>
    }
    if (!customer) return;
    return <MyMapComponent
      lat={customer.shippingLat || 1}
      lng={customer.shippingLng || 1}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCMFLWodTLb-P8Xym4nFeSKfVxlCpXFgBM&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  }

  const goto = () => {
    window.location.href = `https://www.google.com/maps/place/${customer?.shippingLat},${customer?.shippingLng}`;
    return null;
  }

  return (
    <div className="Customers">{customer && (
      <>
        <Form onSubmit={handleSubmit}>
          <MDBBtn
            color="info"
            outline
            className="m-0 px-3 py-2 z-depth-0"
            onClick={() => goto()}
          >
            GO TO CUSTOMERS
          </MDBBtn>
          <Form.Group controlId="sms">
            <Form.Label column={'lg'}>Send sms: <a href={`sms:${customer.phoneNumber}`}>{customer.phoneNumber}</a></Form.Label>
          </Form.Group>
          <Form.Group controlId="call">
            <Form.Label column={'lg'}>Call to: <a href={`tel:${customer.phoneNumber}`}>{customer.phoneNumber}</a></Form.Label>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Name: {customer.firstName + ' ' + customer.lastName} (username: {customer.username})</Form.Label>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email: {customer.email}</Form.Label>
          </Form.Group>
          <Form.Group controlId="shippingAddress">
            <Form.Label>Shipping address: {customer.shippingAddress}</Form.Label>
          </Form.Group>
          <Form.Group controlId="place">
            <Form.Label>If the place is incorrect, put 2GIS link here</Form.Label>
            <MDBInputGroup
              hint="2GIS sharing url in format (https://go.2gis.com/l219f)"
              containerClassName="mb-3"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPlace(event.target.value)}
              append={
                <>
                  <MDBBtn
                    color="info"
                    outline
                    disabled={!place}
                    className="m-0 px-3 py-2 z-depth-0"
                    onClick={() => updateCoordinates()}
                  >
                    Update coordinates
                  </MDBBtn>
                </>
              }
            />
          </Form.Group>
        </Form>
        <div style={{height: '40vh', width: '50vh'}}>
          {renderMap()}
        </div>
      </>
    )}</div>
  );
}

export default Customers;
