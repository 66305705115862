import React, {useEffect, useState} from 'react';
import './App.css';
import {Nav, Navbar} from "react-bootstrap";
import Routes from "./Routes";
import {LinkContainer} from "react-router-bootstrap";
import {AppContext} from './libs/contextLib';
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {onError} from "./libs/errorLib";
import {getCustomers, getUser} from "./api/RestAPI";
import {ICustomer, IUser, UserRole} from "dinenation-types";

const App: React.FC = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [user, setUser] = useState<IUser>({role: UserRole.ADMIN, email: '', id: ''});
  const [isAuthenticating, setAuthenticating] = useState(true);
  const history = useHistory();

  const handleLogout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    history.push("/login");
  }

  const onLoad = async () => {
    try {
      const session = await Auth.currentSession();
      const user = await getUser(session.getAccessToken().payload.sub);
      setUser(user);
      setAuthenticated(true);
      const customersResponse = await getCustomers();
      setCustomers(customersResponse);
    } catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setAuthenticating(false);
  }

  useEffect(() => {
    onLoad();
  }, []);

  if (isAuthenticating) {
    return null;
  }

  const renderNavMenu = () => {
    if (isAuthenticated) {
      return <>
        <LinkContainer to="/kitchen">
          <Nav.Link>Kitchen</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/orders">
          <Nav.Link>Orders</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/addresses">
          <Nav.Link>Addresses</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/ordersMap">
          <Nav.Link>Orders Map</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/customers">
          <Nav.Link>Customers</Nav.Link>
        </LinkContainer>
        <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
      </>
    }
    return <LinkContainer to="/login">
      <Nav.Link>Login</Nav.Link>
    </LinkContainer>
  }

  return (
    <div className="App container py-3">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold text-muted">
            Dinenation
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
            {renderNavMenu()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{isAuthenticated, setAuthenticated, customers, setCustomers, user, setUser}}>
        <Routes/>
      </AppContext.Provider>
    </div>
  );
}

export default App;
