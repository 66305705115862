import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {onError} from "../libs/errorLib";
import {getCoordinates, getOrder} from "../api/RestAPI";
import {Form} from "react-bootstrap";
import {MDBBtn, MDBInputGroup, MDBListGroup, MDBListGroupItem} from "mdbreact";
import {MDBSpinner} from "mdb-react-ui-kit";
import MyMapComponent from "../components/SingleMapComponent";
import {useAppContext} from "../libs/contextLib";
import {findCustomer} from "../libs/utils";
import moment from "moment";
import {ICustomer, IOrder} from "dinenation-types";

interface ParamTypes {
  id: string
}

const Customers: React.FC = () => {
  const {id} = useParams<ParamTypes>();
  const {customers} = useAppContext();
  const [customer, setCustomer] = useState<ICustomer>();
  const [order, setOrder] = useState<IOrder>();
  const [isLoading, setLoading] = useState(false);
  const [weekDay, setWeekDay] = useState('withoutDay');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setLoading(true);
        const currentOrder: IOrder = await getOrder(id);
        const customer = findCustomer(customers, currentOrder.phoneNumber, currentOrder.customerId);
        setCustomer(customer);
        setOrder(currentOrder);
        setWeekDay(moment().format('dddd'));
        if (currentOrder.shippingAddress !== customer.shippingAddress) {
          const coordinates = await getCoordinates(currentOrder.shippingAddress?.postCode + ', ' + currentOrder.shippingAddress?.city + ', ' + currentOrder.shippingAddress?.address1 + ', ' + currentOrder.shippingAddress?.address2 || '');
          console.log(coordinates);
          setLatitude(coordinates.latitude);
          setLongitude(coordinates.longitude);
        } else {
          setLatitude(customer.shippingLat || 1);
          setLongitude(customer.shippingLng || 1);
        }
        setLoading(false);
      } catch (e) {
        console.log('error')
        setLoading(false);
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);
    try {
      setLoading(false);
    } catch (e) {
      onError(e);
      setLoading(false);
    }
  }

  const renderMap = () => {
    if (isLoading || !customer || !order) {
      return <MDBSpinner/>
    }
    return <MyMapComponent
      lat={latitude}
      lng={longitude}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCMFLWodTLb-P8Xym4nFeSKfVxlCpXFgBM&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{height: `100%`}}/>}
      containerElement={<div style={{height: `400px`}}/>}
      mapElement={<div style={{height: `100%`}}/>}
    />
  }

  const goto = () => {
    window.location.href = `https://www.google.com/maps/place/${customer?.shippingLat},${customer?.shippingLng}`;
    return null;
  }

  if (!order) return null;

  return (
    <div className="DriverOrder">{customer && (
      <>
        <Form onSubmit={handleSubmit}>
          <MDBBtn
            color="info"
            outline
            className="m-0 px-3 py-2 z-depth-0"
            onClick={() => goto()}
          >
            GO TO CUSTOMER
          </MDBBtn>
          <Form.Group controlId="weekDay">
            <Form.Label column={'lg'}>Today: {weekDay}</Form.Label>
          </Form.Group>
          {order?.dishesByDays[weekDay] && <MDBListGroup style={{width: "30rem"}}>
            {order?.dishesByDays[weekDay].dishes.map((dish, i) => {
              return <MDBListGroupItem
                key={i.toString()}>{dish.dishType}: {dish.name} - {dish.quantity}</MDBListGroupItem>
            })}
          </MDBListGroup>}
          <Form.Group controlId="sms">
            <Form.Label column={'lg'}>Send sms: <a
              href={`sms:${customer.phoneNumber}`}>{customer.phoneNumber}</a></Form.Label>
          </Form.Group>
          <Form.Group controlId="call">
            <Form.Label column={'lg'}>Call to: <a
              href={`tel:${customer.phoneNumber}`}>{customer.phoneNumber}</a></Form.Label>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Company: {customer.firstName + ' ' + customer.lastName} (username: {customer.username})</Form.Label>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email: {customer.email}</Form.Label>
          </Form.Group>
          {order.customerComment && <Form.Group controlId="comment">
            <Form.Label>Comment: {order.customerComment}</Form.Label>
          </Form.Group>}
          <Form.Group controlId="company">
            <Form.Label>Email: {order.company}</Form.Label>
          </Form.Group>
          <Form.Group controlId="shippingAddress">
            <Form.Label>Shipping address: {order.shippingAddress?.postCode + ', ' + order.shippingAddress?.city + ', ' + order.shippingAddress?.address1 + ', ' + order.shippingAddress?.address2}</Form.Label>
          </Form.Group>
        </Form>
        <div style={{height: '40vh', width: '50vh'}}>
          {renderMap()}
        </div>
      </>
    )}</div>
  );
}

export default Customers;
