import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";

import {
  closeOldOrders,
  createCustomer,
  getOrders,
  getUsers, syncOrders,
  updateCustomer,
  updateOrder
} from "../api/RestAPI";
import "./Orders.css";
import {column} from "./Home";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {FRIDAY, MONDAY, SATURDAY, THURSDAY, TUESDAY, WEDNESDAY, WITHOUT_DAY} from "../libs/weekDays";
import PrintBlock from "../components/PrintBlock";
import Select from 'react-select';
import {useAppContext} from "../libs/contextLib";
import {findCustomer} from "../libs/utils";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {IBox, ICustomer, IOrder, IUser, UserRole} from "dinenation-types";
import {v4} from "uuid";
import {MDBBtn, MDBDataTable} from "mdbreact";
import Loader from "react-loader-spinner";

const weekDays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, WITHOUT_DAY];

interface option {
  driverName?: string;
  driverId?: string;
}

const day = moment().format('dddd');

const Orders: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isDriverChanging, setDriverChanging] = useState(false);
  const [isSyncing, setSyncing] = useState(false);
  const [all, setAll] = useState(false);
  const [orders, setOrders] = useState<{ columns: column[], rows: IOrder[] }>();
  const MySwal = withReactContent(Swal);
  const {customers, user} = useAppContext();
  const [drivers, setDrivers] = useState<option[]>([]);
  const [driversList, setDriversList] = useState<{value: string, label: string}[]>([{value: '', label: 'ALL DRIVERS'}]);
  const [refresh, makeRefresh] = useState(true);
  const history = useHistory();
  const [users, setUsers] = useState<IUser[]>([]);
  const [driverIdForPrint, setDriverIdForPrint] = useState<string>('');
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [companies, setCompanies] = useState<{value: string, label: string}[]>([]);

  const columns: {
    label: string,
    field: string,
    width?: number,
  }[] = [
    {
      label: 'Full Name',
      field: 'fullname',
    },
    {
      label: 'number',
      field: 'wpId',
    },
    {
      label: 'Comment',
      field: 'customerComment',
    },
    {
      label: 'Company',
      field: 'company',
    },
    {
      label: 'Address',
      field: 'shippingAddress',
    },
    {
      label: 'Assigned driver',
      field: 'driver',
      width: 300
    },
  ]

  const isDriver = (user: IUser) => {
    return user.role === UserRole.DELIVERY;
  }

  const load = async (orderStatus?: string) => {
    setLoading(true);
    const ordersResponse = await getOrders({orderStatus: orderStatus});
    const companiesFromOrders: {value: string, label: string}[] = [];
    for (const order of ordersResponse) {
      if (order.company && !companiesFromOrders.find(cfo => cfo.value.toLowerCase() === order.company?.toLowerCase())) {
        companiesFromOrders.push({value: order.company, label: order.company})
      }
      if (!order.assignedUserName) {
        const relatedCustomer = findCustomer(customers, order.phoneNumber, order.customerId);
        if (relatedCustomer && relatedCustomer.assignedUserName && relatedCustomer.assignedUserSub) {
          order.assignedUserName = relatedCustomer.assignedUserName;
          order.assignedUserSub = relatedCustomer.assignedUserSub;
        }
      }
    }
    setCompanies([...companiesFromOrders])
    if (isDriver(user)) {
      columns.splice(columns.indexOf({
        label: 'Assigned driver',
        field: 'driver',
        width: 300
      }), 1);
      columns.splice(columns.indexOf({
        label: 'Company',
        field: 'company',
      }), 1);
      columns.splice(columns.indexOf({
        label: 'Address',
        field: 'shippingAddress',
      }), 1);
    }
    setOrders({
      columns: columns,
      rows: ordersResponse.filter((order: IOrder) => {
        if (isDriver(user)) {
          if (order.dishesByDays[day] && order.dishesByDays[day].dishes.length > 0) {
            return order.assignedUserSub === user.id
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
    });
    const usersResponse = await getUsers();
    setUsers(usersResponse)
    const drvs: option[] = [];
    for (const targetUser of usersResponse) {
      if (isDriver(targetUser)) {
        drvs.push({driverName: targetUser.firstName, driverId: targetUser.id})
      }
    }
    setDrivers(drvs);
    setLoading(false);
  }

  useEffect(() => {
    load('processing');
    const drs: {value: string, label: string}[] = [];
    for (const driverToAddToList of drivers) {
      drs.push({value: driverToAddToList.driverId!, label: driverToAddToList.driverName!})
    }
    setDriversList([...drs, ...driversList])
  }, [refresh]);

  const renderPrintButtons = () => {
    if (orders && user.role === UserRole.ADMIN) {
      return weekDays.map((targetWeekDay) => {
        return <PrintBlock users={users} key={Math.ceil(Math.random() * 100000).toString()}
                           orders={orders.rows}
                           driverId={driverIdForPrint}
                           selectedCompaniesToPrint={selectedCompanies}
                           weekDay={targetWeekDay}/>
      })
    }
  }

  const changeDriver = async (option: option | null, order: IOrder) => {
    if (option && order.id && orders) {
      setDriverChanging(true);
      try {
        const newRows = [...orders.rows];
        const found = newRows.find((newRow) => newRow.id === order.id);
        if (found) {
          found.assignedUserName = option.driverName;
        }
        setOrders({columns: columns, rows: newRows})

        for (let day = 0; day < weekDays.length; day++) {
          if (order.dishesByDays[weekDays[day]]) {
            for (let i = 0; i < order.dishesByDays[weekDays[day]].boxes.length; i++) {
              order.dishesByDays[weekDays[day]].boxes[i].assignedUserName = option.driverName;
              order.dishesByDays[weekDays[day]].boxes[i].assignedUserId = option?.driverId;
            }
          }
        }

        await updateOrder(order.id, {
          dishesByDays: order.dishesByDays,
          assignedUserName: option.driverName,
          assignedUserSub: option.driverId
        });

        const relatedCustomer = findCustomer(customers, order.phoneNumber, order.customerId);
        if (relatedCustomer) {
          const newCustomer = {...relatedCustomer};
          newCustomer.assignedUserName = option.driverName;
          newCustomer.assignedUserSub = option.driverId;
          delete newCustomer.updatedAt;
          delete newCustomer.createdAt;
          delete newCustomer.id;
          await updateCustomer(relatedCustomer.id, newCustomer);
        } else {
          const newCustomer: ICustomer = {
            id: v4(),
            firstName: order.firstName,
            lastName: order.lastName,
            email: order.email,
            shippingAddress: order.shippingAddress?.id || "",
            shippingLat: order.shippingLat,
            shippingLng: order.shippingLng,
            phoneNumber: order.phoneNumber,
            assignedUserSub: option.driverId,
            assignedUserName: option.driverName,
          };
          await createCustomer(newCustomer);
        }
      } catch (e) {
        alert('Can\'t update driver because: ' + JSON.stringify(e));
      } finally {
        setDriverChanging(false);
      }
    }
  }

  const renderOrders = () => {
    if (isLoading) {
      return <Loader
        type="Puff"
        color="#00BFFF"
        height={200}
        width={200}
        visible={isLoading}
      />
    }
    if (orders) {
      const data = {
        columns: orders.columns,
        rows: [
          ...orders.rows.map((row: IOrder) => {
            return {
              ...row,
              fullname: row.firstName + ' ' + row.lastName,
              driver: (<div style={{width: '150px'}}>
                  {!isDriverChanging && <Select<option>
                    options={drivers}
                    getOptionLabel={(option: option) => option.driverName || ''}
                    getOptionValue={(option: option) => option.driverId || ''}
                    isClearable
                    value={row.assignedUserName ? {
                      driverName: row.assignedUserName,
                      driverId: row.assignedUserSub
                    } : null}
                    placeholder='Not assigned'
                    onChange={(option) => changeDriver(option, row)}
                  />}
                  {isDriverChanging && <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={30}
                    width={30}
                    visible={isDriverChanging}
                  />}

                </div>
              ),
              shippingAddress: row.shippingAddress?.city + ', ' + row.shippingAddress?.address1 + ', ' + row.shippingAddress?.address2,
              clickEvent: () => isDriver(user) ? history.push('/orders/' + row.id) : ''
            }
          }),
        ],
      };

      return (
        <>
          <Select defaultValue={{value: '', label: 'ALL DRIVERS'}} onChange={e => setDriverIdForPrint(e?.value!)} options={driversList}/>
          <Select isMulti onChange={e => {
            setSelectedCompanies(e.map(sc => sc.value))
          }} options={companies}/>
          {renderPrintButtons()}
          <MDBBtn
            disabled={isSyncing}
            color={isSyncing ? 'grey' : 'blue'}
            onClick={async () => {
              setSyncing(true);
              try {
                const syncResult = await syncOrders();
                if (syncResult.success) {
                  alert('Orders updated from website');
                } else {
                  alert('Orders NOT updated, something went wrong');
                }
              } catch (e) {
                alert('Orders NOT updated, something went wrong');
              } finally {
                makeRefresh(!refresh);
                setSyncing(false)
              }
            }}>Update orders</MDBBtn>
          {!isDriver(user) && <MDBBtn
            color={'green'}
            disabled={isSyncing}
            onClick={async () => {
              if (all) {
                load('processing');
              } else {
                load();
              }
              setAll(!all);
            }}>{all ? 'Only in progress' : 'Show all orders'} ({orders.rows.length})</MDBBtn>}
          {!isDriver(user) && <MDBBtn
            color={'red'}
            disabled={isSyncing}
            onClick={async () => {
              setSyncing(true);
              const closeResult = await closeOldOrders();
              if (closeResult.success) {
                MySwal.fire({
                  title: 'Success!',
                  icon: 'info',
                  text: 'Old orders successfully closed'
                }).then(() => {
                  setSyncing(false);
                  makeRefresh(!refresh)
                })
              } else {
                MySwal.fire({
                  title: 'Fail!',
                  icon: 'error',
                  text: `Error: ${closeResult.reason}`
                }).then(() => {
                  setSyncing(false);
                  makeRefresh(!refresh)
                })
              }
            }}>Close orders</MDBBtn>}
          <MDBDataTable
            striped
            bordered
            hover
            entries={100}
            data={data}
          />
        </>
      );
    }
  }

  return (
    <div className="Orders">
      {renderOrders()}
    </div>
  );
}

export default Orders;
