import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import "./NewCustomer.css";
import { createCustomer } from "../api/RestAPI";
import { useFormFields } from "../libs/hooksLib";
import {v4} from "uuid";

const NewCustomer: React.FC = () => {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    shippingAddress: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    return (
      fields.email.length > 0 &&
      fields.shippingAddress.length > 0
    );
  }

  const handleSubmit = async(event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createCustomer({
        id: v4(),
        email: fields.email,
        shippingAddress: fields.shippingAddress
      });
      history.push("/");
    } catch (e) {
      console.log(e);
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="NewCustomer">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="shippingAddress">
          <Form.Label>Shipping adress</Form.Label>
          <Form.Control
            type="shippingAddress"
            value={fields.shippingAddress}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}

export default NewCustomer;
