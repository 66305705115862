import {createContext, useContext} from "react";
import {ICustomer, IUser, UserRole} from "dinenation-types";

const customer: ICustomer = {
  id: "",
  email: '',
  shippingAddress: ''
};

const user: IUser = {
  role: UserRole.ADMIN,
  id: '',
  email: ''
}

export const AppContext = createContext({
  isAuthenticated: false,
  setAuthenticated: (_: boolean) => {
  },
  customers: [customer],
  setCustomers: (customer: ICustomer[]) => {
  },
  user: user,
  setUser: (user: IUser) => {
  },
});

export const useAppContext = () => {
  return useContext(AppContext);
}
