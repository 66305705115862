import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewCustomer from "./containers/NewCustomer";
import Customers from "./containers/Customers";
import Orders from "./containers/Orders";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import OrdersMap from "./containers/OrdersMap";
import Kitchen from "./containers/Kitchen";
import DriverOrder from "./containers/DriverOrder";
import Addresses from "./containers/Addresses";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Orders />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/customers/new">
        <NewCustomer />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/customers/:id">
        <Customers />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/orders">
        <Orders />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/addresses">
        <Addresses />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/orders/:id">
        <DriverOrder />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/kitchen">
        <Kitchen />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/ordersMap">
        <OrdersMap />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/customers">
        <Home />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <NotFound />
      </AuthenticatedRoute>
    </Switch>
  );
}

export default Routes;
