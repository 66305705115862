import React, {useEffect, useState} from "react";
import "./Addresses.css";
import {ListGroup} from "react-bootstrap";
import {useAppContext} from "../libs/contextLib";
import {getAddresses} from "../api/RestAPI";
import {onError} from "../libs/errorLib";
import {AiFillCheckCircle, AiFillMinusCircle} from "react-icons/all";
import {MDBDataTable} from 'mdbreact';
import {useHistory} from "react-router-dom";
import {IAddress} from "dinenation-types";

export interface column {
  label: string;
  field: string;
  width?: number;
  attributes?: {
    'aria-controls': string;
    'aria-label': string;
  },
}

const columns = [
  {
    label: 'ZIP',
    field: 'postCode',
  },
  {
    label: 'City',
    field: 'city',
  },
  {
    label: 'Address 1',
    field: 'address1',
  },
  {
    label: 'Address 2',
    field: 'address2',
  },
  {
    label: 'Coordinates',
    field: 'coordinates',
  },
  {
    label: 'Verified',
    field: 'verified',
  },
]

const Addresses: React.FC = () => {
  const [addressesData, setAddressesData] = useState<{ columns: column[], rows: IAddress[] }>();
  const {isAuthenticated} = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const onLoad = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        const addressesResponse = await getAddresses();
        setAddressesData({columns: columns, rows: addressesResponse});
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  const verified = (row: IAddress) => {
    if (row.verified) {
      return <AiFillCheckCircle color={'green'} size={27}/>
    } else {
      return <AiFillMinusCircle color={'red'} size={27}/>
    }
  }

  const renderAddressesList = () => {
    if (addressesData) {
      const data = {
        columns: addressesData.columns,
        rows: [
          ...addressesData.rows.map((row: IAddress) => ({
            ...row,
            coordinates: (
              row.shippingLat + ', ' + row.shippingLng
            ),
            verified: verified(row),
          })),
        ],
      };
      return (
        <>
          <MDBDataTable
            striped
            bordered
            hover
            data={data}
          />
        </>
      );
    }
  }

  return (
    <div className="Addresses">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">All addresses</h2>
      <ListGroup>{!isLoading && renderAddressesList()}</ListGroup>
    </div>
  );
}

export default Addresses;
