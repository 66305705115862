import React, {useState, useEffect} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {MDBContainer, MDBDataTable, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane} from "mdbreact";
import {getKitchenOrders} from "../api/RestAPI";
import {IKitchenOrder} from "dinenation-types";

const columns = [
  {
    label: 'Dish name',
    field: 'name',
  },
  {
    label: 'Quantity',
    field: 'quantity',
  }
];

const Orders: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [orders, setOrders] = useState<IKitchenOrder>();

  const loadOrders = async () => {
    setLoading(true);
    const orders = await getKitchenOrders()
    setOrders(orders);
    setLoading(false);
  }

  const changeTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    loadOrders();
  }, []);

  if (isLoading || !orders) return null;

  return (
    <div className="Kitchen">
      <MDBContainer>
        <MDBNav className="nav-tabs mt-5">
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "1"} onClick={() => changeTab("1")} role="tab">
              Monday
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "2"} onClick={() => changeTab("2")} role="tab">
              Tuesday
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "3"} onClick={() => changeTab("3")} role="tab">
              Wednesday
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "4"} onClick={() => changeTab("4")} role="tab">
              Thursday
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "5"} onClick={() => changeTab("5")} role="tab">
              Friday
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={activeTab === "6"} onClick={() => changeTab("6")} role="tab">
              Without day
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeTab}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                rows: orders['Monday'],
              }}
            />
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                // @ts-ignore
                rows: orders['Tuesday'],
              }}
            />
          </MDBTabPane>
          <MDBTabPane tabId="3" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                // @ts-ignore
                rows: orders['Wednesday'],
              }}
            />
          </MDBTabPane>
          <MDBTabPane tabId="4" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                // @ts-ignore
                rows: orders['Thursday'],
              }}
            />
          </MDBTabPane>
          <MDBTabPane tabId="5" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                // @ts-ignore
                rows: orders['Friday'],
              }}
            />
          </MDBTabPane>
          <MDBTabPane tabId="6" role="tabpanel">
            <MDBDataTable
              striped
              bordered
              entries={100}
              hover
              data={{
                columns,
                // @ts-ignore
                rows: orders['withoutDay'],
              }}
            />
          </MDBTabPane>
        </MDBTabContent>
      </MDBContainer>
    </div>
  );
}

export default Orders;
