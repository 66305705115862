import {MDBBtn} from "mdbreact";
import React from "react";
import {IBox, IOrder, IUser} from "dinenation-types";
import jsPDF from "jspdf";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import moment from "moment";

// @ts-ignore
const cyrillicToTranslit = new CyrillicToTranslit();

interface Props {
  weekDay: string;
  orders: IOrder[];
  users: IUser[];
  driverId?: string;
  selectedCompaniesToPrint?: string[];
}

export interface Sticker {
  orderNumber: string;
  firstName: string;
  lastName: string;
  dishName: string;
  driverName: string;
  company: string;
  qrCode: string;
  boxId: string;
  driverId?: string;
  date: string;
}

const PrintBlock: React.FC<Props> = ({weekDay, orders, users, driverId, selectedCompaniesToPrint}) => {
  const printBoxes: Sticker[] = [];

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (order.dishesByDays[weekDay]) {
      for (let j = 0; j < order.dishesByDays[weekDay].boxes.length; j++) {
        const box = order.dishesByDays[weekDay].boxes[j];
        if (driverId) {
          if (driverId === order.assignedUserSub) {
            printBoxes.push({
              orderNumber: order.wpId.toString(),
              firstName: order.firstName || "",
              lastName: order.lastName || "",
              dishName: box.label,
              driverName: order.assignedUserName || 'NIY',
              company: order.company || "",
              qrCode: box.qrCode,
              boxId: box.id,
              driverId: order.assignedUserSub,
              date: moment().format('DD.MMM')
            })
          }
        } else {
          printBoxes.push({
            orderNumber: order.wpId.toString(),
            firstName: order.firstName || "",
            lastName: order.lastName || "",
            dishName: box.label,
            driverName: order.assignedUserName || 'NIY',
            company: order.company || "",
            qrCode: box.qrCode,
            boxId: box.id,
            driverId: order.assignedUserSub,
            date: moment().format('DD.MMM')
          })
        }
      }
    }
  }

  let sortedStickers: Sticker[] = [];
  for (const user of users) {
    const stickers = printBoxes.filter(sticker => sticker.driverId === user.id)
      .sort((a, b) => {
        if (a.dishName.toLowerCase().includes('salad')) return -1;
        return 1;
      });
    sortedStickers = sortedStickers.concat(stickers)
  }

  const calculateUniqueOrders = (weekday: string) => {
    const currentDayOrders = orders.filter(order => order.dishesByDays[weekDay] && order.dishesByDays[weekDay].boxes.length > 0);
    const uniqueOrders: IOrder[] = [];
    for (let i = 0; i < currentDayOrders.length; i++) {
      if (!uniqueOrders.includes(currentDayOrders[i])) {
        uniqueOrders.push(currentDayOrders[i]);
      }
    }
    return uniqueOrders.length;
  }

  return <>
    <MDBBtn onClick={() => {
      const doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: [60, 45],
      });
      if (orders) {
        if (selectedCompaniesToPrint && selectedCompaniesToPrint.length > 0) {
          const companyOrders = orders.filter(order => {
            return order.company && selectedCompaniesToPrint.find(sctp => sctp.toLowerCase() === order.company?.toLowerCase()) &&
              order.dishesByDays[weekDay].boxes && order.dishesByDays[weekDay].boxes.length > 0
          });
          for (let i = 0; i < companyOrders.length; i++) {
            const sticker = companyOrders[i]
            if (driverId && driverId !== sticker.assignedUserSub) {
              continue
            }
            if (i > 0) {
              doc.addPage([60, 45], "l");
            }
            // doc.addImage(sticker, 0, 0, 20, 20)
            doc.setFontSize(15);
            doc.text(cyrillicToTranslit.transform(sticker.firstName, '_'), 20, 8,)
            doc.text(cyrillicToTranslit.transform(sticker.lastName, '_'), 20, 16,)
            doc.setFont("times", "bold");
            doc.setFontSize(19);
            doc.text(cyrillicToTranslit.transform(sticker.company, '_'), 20, 24)
            // if (sticker.dishName.split("+").length > 1) {
            //   doc.text(sticker.dishName.split("+")[1], 2, 32)
            // }
            if (sticker.assignedUserName) {
              doc.addImage(`assets/images/${sticker.assignedUserName.toLowerCase()}.png`, 45, 34, 10, 10)
            }
            doc.setFont("times", "normal");
            doc.setFontSize(12);
            // doc.text(cyrillicToTranslit.transform(sticker.company, '_'), 2, 40)
            doc.setFontSize(16);
            doc.text(sticker.wpId, 20, 40)
          }
          doc.save(weekDay + ".pdf")
        } else {
          for (let i = 0; i < sortedStickers.length; i++) {
            const sticker = sortedStickers[i]
            if (i > 0) {
              doc.addPage([60, 45], "l");
            }
            doc.addImage(sticker.qrCode, 0, 0, 20, 20)
            doc.setFontSize(12);
            doc.text(sticker.date, 45, 5)
            doc.setFontSize(15);
            doc.text(cyrillicToTranslit.transform(sticker.firstName, '_'), 20, 8,)
            doc.text(cyrillicToTranslit.transform(sticker.lastName, '_'), 20, 16,)
            doc.setFont("times", "bold");
            doc.setFontSize(19);
            doc.text(sticker.dishName.split("+")[0], 2, 24)
            if (sticker.dishName.split("+").length > 1) {
              doc.text(sticker.dishName.split("+")[1], 2, 32)
            }
            if (sticker.driverName) {
              doc.addImage(`assets/images/${sticker.driverName.toLowerCase()}.png`, 45, 34, 10, 10)
            }
            doc.setFont("times", "normal");
            doc.setFontSize(12);
            doc.text(cyrillicToTranslit.transform(sticker.company.substr(0, 8), '_'), 2, 40)
            doc.setFontSize(16);
            doc.text(sticker.orderNumber, 20, 40)
          }
          doc.save(weekDay + ".pdf")
        }

      } else {
        alert('Orders not loaded yet, wait a bit');
      }
    }} color="primary">Print {weekDay} ({calculateUniqueOrders(weekDay)})</MDBBtn>
  </>
}

export default PrintBlock;
