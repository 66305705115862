const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://g1qxsfssic.execute-api.us-east-1.amazonaws.com",
    DEV_URL: "https://0zjzsdx6sh.execute-api.us-east-1.amazonaws.com",
  },
  syncGateway: {
    REGION: "us-east-1",
    URL: "https://n9ou8e2eof.execute-api.us-east-1.amazonaws.com",
    DEV_URL: "https://jw5mnusbsd.execute-api.us-east-1.amazonaws.com",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Ocm8VdpaW",
    APP_CLIENT_ID: "3h8p1ar254o4nffuhdv9qvdnlp",
    IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
  },
};

export default config;
