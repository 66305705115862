import React, { useState, useEffect } from "react";
import { getOrders} from "../api/RestAPI";
import { useAppContext } from "../libs/contextLib";
import ManyPointsMapComponent, { Coordinate } from "../components/ManyPointsMapComponent";
import {IOrder} from "dinenation-types";

const Orders: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState<Coordinate[]>([]);
  const {customers} = useAppContext();

  useEffect(() => {
    const loadMapCoordinates = async () => {
      setLoading(true);
      const orders: IOrder[] = await getOrders()
      const mapPlaces = [];
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const customer = customers.find(cus => cus.wpId === order.customerId)
        if (customer) {
          const coordinate:Coordinate = {
            id: i,
            name: `${customer.firstName} ${customer.lastName}, ${customer.shippingAddress}`,
            latitude: customer.shippingLat || 1,
            longitude: customer.shippingLng || 1,
          };
          mapPlaces.push(coordinate)
        } else {
          const coordinate:Coordinate = {
            id: i,
            name: `${order.firstName + ' ' + order.lastName}, ${order.shippingAddress}`,
            latitude: order.shippingLat || 1,
            longitude: order.shippingLng || 1,
          };
          mapPlaces.push(coordinate)
        }
      }
      const kitchen: Coordinate = {
        id: 123,
        name: "kitchen",
        latitude:34.68398,
        longitude:33.037861
      }
      mapPlaces.push(kitchen);
      setCoordinates(mapPlaces);
      setLoading(false);
      console.log(mapPlaces.length)
    }

    loadMapCoordinates();
  }, [customers]);

  return (
    <div className="Coordinates">
      {!isLoading && coordinates && <ManyPointsMapComponent
        // @ts-ignore
        center={{ lat: 34.6671732, lng: 33.0132906 }}
        zoom={10}
        places={coordinates}
      />}
    </div>
  );
}

export default Orders;
