import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import "./LoaderButton.css";

interface Props extends ButtonProps{
  isLoading: boolean;
  className?: string;
  disabled?: boolean;
}

const LoaderButton: React.FC<Props> =
  ({
     isLoading,
     className = "",
     disabled = false,
    ...props
   }) => {
    return (
      <Button
        disabled={disabled || isLoading}
        className={`LoaderButton ${className}`}
        {...props}
      >
        {isLoading && <BsArrowRepeat className="spinning"/>}
        {props.children}
      </Button>
    );
  }

export default LoaderButton;
