import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import React from "react";

interface Props {
  lat: number;
  lng: number;
}

const SingleMapComponent:React.FC<Props> = ({lat, lng}) => {
  return <GoogleMap
    defaultZoom={14}
    defaultCenter={{lat, lng}}
  >
    <Marker position={{lat, lng}}/>
  </GoogleMap>
}

export default withScriptjs(withGoogleMap(SingleMapComponent));
